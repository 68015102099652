import { defineNuxtRouteMiddleware, navigateTo } from '#app';
import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware((to) => {
    const store = useAuthStore();
    const cityComposable = useCities();

    const city = to.params.city as string;

    if (store.address?.city?.name != null) {
        let findCity = cityComposable.getCityByName(store.address?.city?.name);

        if (!findCity) {
            findCity = cityComposable.getCityBySlug('moscow');
        }

        cityComposable.setCurrentCity(findCity.slug);

        if (!city) {
            return navigateTo(`/${findCity.slug}` + to.fullPath);
        }

        if (city != findCity.slug) {
            let newPath = to.fullPath.replace(`/${city}`, `/${findCity.slug}`);
            return navigateTo(newPath);
        }

    } else if (city != null) {
        if (!cityComposable.cities.includes(city.toLowerCase())) {
            cityComposable.setCurrentCity('moscow');

            let newPath = to.fullPath.replace(`/${city}`, `/moscow`);
            return navigateTo(newPath);
        }
    } else {
        return navigateTo(`/moscow` + to.fullPath);
    }
});